import $ from 'jquery';
import 'what-input';
import 'datatables.net-zf';
//import 'datatables.net-select-zf';
//import 'datatables.net-buttons-zf';
//import 'datatables.net-rowgroup-zf';
import 'select2';
import 'leaflet';
import '@eastdesire/jscolor';
import 'jquery-ui/ui/widgets/datepicker';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;
require('foundation-sites');
require( 'datatables.net-buttons/js/buttons.colVis.js' )();
window.Dropzone = require('dropzone');
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
    // myDropzone is the configuration for the element that has an id attribute
    // with the value my-dropzone (or myDropzone)
    Dropzone.autoDiscover = false;

    $(function() {
        //Dropzone class
          $('.dropzone').dropzone({
            previewsContainer: ".dropzone-previews",
            previewTemplate: document.getElementById('preview-template').innerHTML,            
            paramName: "file",
            maxFilesize: 2,
            maxFiles: 2,
            addRemoveLinks: true,
            acceptedFiles: "image/*,application/pdf",
            dictDefaultMessage: "<i class=\"fas fa-folder-open\"></i><span>Upload your documents - Files should be PDF or DOC(X). Drag and drop files here or </span><a href='#' class='button tiny margin-top-1'>Browse Files</a>",
            init: function() {
                this.on("complete", function(file) {
                    $(".dz-remove").html("<div style='margin-left:5px;'> <span class='fa fa-trash' style='font-size: 1.0em'></span></div>");
                });
            }
        });
    });
    
      function copyToClipboard(element) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).text()).select();
        document.execCommand("copy");
        $temp.remove();
      }
      